import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { observer } from 'mobx-react';
import ReactApexChart from 'react-apexcharts';
import MicChartPage from './micChart';
import ReactSelect from 'react-select';
import DeviceService from '../../../services/DeviceService';

const FFTChartPage = observer(() => {
    const [sensingTime, setSensingTime] = useState('')
    const [frequencie, setFrequencie] = useState([])
    const [magnitude, setMagnitude] = useState([])
    const [maxLine, setMaxLine] = useState([])
    const [wsocket, setWsocket] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [reconnect, setReconnect] = useState(0)
    const [screenWidth, setScreenWidth] = useState(0)

    const [scale, setScale] = useState(500)

    const range = [
        { value: 500, label: '0~500Hz' },
        { value: 1000, label: '0~1000Hz' },
        { value: 4000, label: '0~4000Hz' },
        { value: 12000, label: '0~12000Hz' },
    ]

    useEffect(() => { 
        

    }, [scale])

    const IMEI = new URL(window.location.href).searchParams.get('IMEI');

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    window.addEventListener('message', (event) => {
        console.log('event', event.data)
    });

    useEffect(() => {
        let timer

        setInterval(() => {
            if (!wsocket) {
                setReconnect(r => r+1)
            } else if (!isPlaying) {
                wsocket.send(JSON.stringify({ IMEI, maxLine, scale }));
            }
        }, 5000)

        return (
            clearInterval(timer)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // 컴포넌트가 마운트될 때 웹소켓 연결을 수행합니다.
        let ws

        async function fetchData() {
            const deviceInfo = await DeviceService.getDeviceInfo({ IMEI })
            if (deviceInfo.data?.device?.Model === 'RSX-511') {
                ws = new WebSocket('wss://sensor.metainnotech.co.kr/socket/v1/dashboard/fft');
            } else {
                ws = new WebSocket('wss://sensor.rnslab.com/socket/v1/dashboard/fft');
            }
            // 웹소켓 이벤트 리스너를 설정합니다.
            ws.onopen = () => {
                setWsocket(ws)
                console.log('웹소켓이 연결되었습니다.');
                ws.send(JSON.stringify({ IMEI, maxLine, scale }));
                setIsPlaying(false)
            };

            ws.onmessage = (event) => {
                setIsPlaying(true)
                // 서버로부터 메시지를 수신했을 때 실행됩니다.
                let data = JSON.parse(event.data)

                if (data?.frequencie) setFrequencie(data?.frequencie)
                if (data?.magnitude) setMagnitude(data?.magnitude)
                if (data?.maxLine) setMaxLine(data?.maxLine)
                if (data?.sensingTime) setSensingTime(data?.sensingTime)

                ws.send(JSON.stringify({ IMEI, maxLine, scale }));
                setIsPlaying(false)
            };

            ws.onclose = () => {
                console.log('웹소켓이 닫혔습니다.');
            };
        }

        fetchData();

        // 컴포넌트가 언마운트될 때 웹소켓 연결을 종료합니다.
        return () => {
            ws.close();
            setWsocket(null)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconnect]); 

    let options = {
        legend: {
            show: true
        },
        series: [
            {
                name: "Now",
                data: magnitude
            },
            {
                name: "Max",
                data: maxLine
            },
        ],
        colors: [
            '#6666ff', // blue
            '#ff6666', // red
        ],
        chart: {
            type: 'area',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            enabled: false,
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shadeIntensity: 1,
        //         inverseColors: false,
        //         opacityFrom: 0.5,
        //         opacityTo: 0,
        //         stops: [0, 90, 100]
        //     },
        // },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: 'dB',
                offsetX: 32,
                offsetY: -165,
                rotate: 0,
                style: {
                    fontWeight: '#000',
                }
            },
            max: 0,
            min: -140
        },
        xaxis: {
            categories: frequencie,
            logarithmic: true,
            tickAmount: 7,
            labels: {
                formatter: function (val, i) {
                    return val < 1000 ? parseInt(val / 10) * 10 : parseInt(val / 100) * 100;
                },
                rotate: 0,
                rotateAlways: false,
            },
            title: {
                text: '주파수(Hz)',
                rotate: 0,
                offsetY: -10,
                style: {
                    fontWeight: '#000',
                }
            },
            rotate: 0,
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val + ' Hz'
                }
            }
        },
        annotations: {
            xaxis: frequencie.filter(_ => _ % (scale / 10) < 1).map((item, index) => {
                return {
                    x: item,
                    borderColor: '#999',
                }
            })
        }
    };


    return (
        <>

            <Page className='scroll-zone' style={{  overflowX: 'hidden' }}>
                <PageWrapper>
                    {/* <div>{sensingTime}</div> */}
                    <div style={{width: 150, marginLeft: 20}}>
                        <ReactSelect
                            isSearchable={false} 
                            options={range}
                            value={range.find(r => r.value === scale)}
                            onChange={(e) => {
                                setScale(e.value)
                            }}
                        />
                    </div>
                    <ReactApexChart
                        style={{marginLeft: 10}}
                        options={options}
                        series={options.series}
                        width={screenWidth}
                        type={'line'}
                        height={400}
                    />
                </PageWrapper>
            </Page>
        </>
    );
});

export default FFTChartPage;
