import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactApexChart from 'react-apexcharts';
import Card, { CardBody, CardSubTitle, CardTitle } from '../../../../components/bootstrap/Card';
import PacketService from '../../../../services/PacketService';
import moment from 'moment';

const weekNumberByMonth = (dateFormat) => {
    const inputDate = new Date(dateFormat);

    // 인풋의 년, 월
    let year = inputDate.getFullYear();
    let month = inputDate.getMonth() + 1;

    // 목요일 기준 주차 구하기
    const weekNumberByThurFnc = (paramDate) => {

        const year = paramDate.getFullYear();
        const month = paramDate.getMonth();
        const date = paramDate.getDate();

        // 인풋한 달의 첫 날과 마지막 날의 요일
        const firstDate = new Date(year, month, 1);
        const lastDate = new Date(year, month + 1, 0);
        const firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay();
        const lastDayOfweek = lastDate.getDay();

        // 인풋한 달의 마지막 일
        const lastDay = lastDate.getDate();

        // 첫 날의 요일이 금, 토, 일요일 이라면 true
        const firstWeekCheck = firstDayOfWeek === 5 || firstDayOfWeek === 6 || firstDayOfWeek === 7;
        // 마지막 날의 요일이 월, 화, 수라면 true
        const lastWeekCheck = lastDayOfweek === 1 || lastDayOfweek === 2 || lastDayOfweek === 3;

        // 해당 달이 총 몇주까지 있는지
        const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7);

        // 날짜 기준으로 몇주차 인지
        let weekNo = Math.ceil((firstDayOfWeek - 1 + date) / 7);

        // 인풋한 날짜가 첫 주에 있고 첫 날이 월, 화, 수로 시작한다면 'prev'(전달 마지막 주)
        if (weekNo === 1 && firstWeekCheck) weekNo = 'prev';
        // 인풋한 날짜가 마지막 주에 있고 마지막 날이 월, 화, 수로 끝난다면 'next'(다음달 첫 주)
        else if (weekNo === lastWeekNo && lastWeekCheck) weekNo = 'next';
        // 인풋한 날짜의 첫 주는 아니지만 첫날이 월, 화 수로 시작하면 -1;
        else if (firstWeekCheck) weekNo = weekNo - 1;

        return weekNo;
    };

    // 목요일 기준의 주차
    let weekNo = weekNumberByThurFnc(inputDate);

    // 이전달의 마지막 주차일 떄
    if (weekNo === 'prev') {
        // 이전 달의 마지막날
        const afterDate = new Date(year, month - 1, 0);
        year = month === 1 ? year - 1 : year;
        month = month === 1 ? 12 : month - 1;
        weekNo = weekNumberByThurFnc(afterDate);
    }
    // 다음달의 첫 주차일 때
    if (weekNo === 'next') {
        year = month === 12 ? year + 1 : year;
        month = month === 12 ? 1 : month + 1;
        weekNo = 1;
    }

    return month + '월' + weekNo + '주';
}

const StData = observer(({ IMEI, type, title }) => {
    const [micStData, setMicStData] = useState([])
    const [micStLabelData, setMicStLabelData] = useState([])
    const [xyzStData, setXyzStData] = useState([])
    const [xyzStLabelData, setXyzStLabelData] = useState([])

    const [micMin, setMicMin] = useState(0)
    const [micMax, setMicMax] = useState(0)
    const [micAvg, setMicAvg] = useState(0)
    const [xyzMin, setXyzMin] = useState(0)
    const [xyzMax, setXyzMax] = useState(0)
    const [xyzAvg, setXyzAvg] = useState(0)

    useEffect(() => {
        PacketService.getStData({
            IMEI: IMEI,
            type: type
        }).then(r => {
            setMicMin(r.data.micMin)
            setMicMax(r.data.micMax)
            setMicAvg(r.data.micAvg)
            setXyzMin(r.data.xyzMin === 100000 ? 0 : r.data.xyzMin)
            setXyzMax(r.data.xyzMax)
            setXyzAvg(r.data.xyzAvg === 'NaN' ? 0 : r.data.xyzAvg)

            let micTempData = []
            let micTempLabelData = []
            r.data.mic.map((m, i) => {
                micTempData.push(m.close)
                let label = ''
                if (type === 'day') label = i % 2 !== 0 ? moment(m.rowData).format('HH') : ''
                if (type === 'week') label = moment(m.date, 'X').format('DD')
                if (type === 'month') label = weekNumberByMonth(moment(m.date, 'X').format('YYYY-MM-DD'))
                micTempLabelData.push(label)
            })

            setMicStData(micTempData)
            setMicStLabelData(micTempLabelData)


            let micTempData2 = []
            let micTempLabelData2 = []
            r.data.xyz.map((m, i) => {
                micTempData2.push(m.close)
                let label = ''
                if (type === 'day') label = i % 2 !== 0 ? moment(m.rowData).format('HH') : ''
                if (type === 'week') label = moment(m.date, 'X').format('DD')
                if (type === 'month') label = weekNumberByMonth(moment(m.date, 'X').format('YYYY-MM-DD'))
                micTempLabelData2.push(label)
            })

            setXyzStData(micTempData2)
            setXyzStLabelData(micTempLabelData2)
        })
    }, [IMEI, type]);


    let options = {
        series: [{
            name: "소음",
            data: micStData
        }],
        labels: micStLabelData,
        chart: {
            animations: {
                enabled: false
            },
            type: 'line',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20,
        },
        colors: [
            '#008000', // green
            '#ff6666', // red
            '#6666ff', // blue
        ],
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: 'dB',
                offsetX: 24,
                offsetY: -180,
                rotate: 0,
                style: {
                    fontWeight: '#000',
                }
            },
            tickAmount: 5,
        },
        xaxis: {
            // tickAmount: 7,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
                rotate: 0,
                rotateAlways: false,
            },
            title: {
                text: '',
                rotate: 0,
            },
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val) {
                    return val + ' '
                }
            }
        },
        annotations: {
            xaxis: micStLabelData.map(x =>
                x !== '' ? {
                    x: x, // x 축의 값을 기준으로 세로선을 그립니다.
                    borderColor: '#cecece',
                    strokeDashArray: 0,
                    opacity: 0.5,
                } : '').filter(x => x !== ''),
        }
        
    };

    let options2 = {
        series: [{
            name: "진동",
            data: xyzStData,
        }], 
        labels: xyzStLabelData,
        chart: {
            animations: {
                enabled: false
            },
            type: 'line',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20,
        },
        colors: [
            '#008000', // green
            '#ff6666', // red
            '#6666ff', // blue
        ],
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: 'm/s²',
                offsetX: 27,
                offsetY: -300,
                rotate: 0,
                style: {
                    fontWeight: '#000',
                }
            },
            tickAmount: 5,
        },
        xaxis: {
            // tickAmount: 7,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
                rotate: 0,
                rotateAlways: false,
            },
            title: {
                text: '',
                rotate: 0,
            },
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val) {
                    return val + ' '
                }
            }
        },
        annotations: {
            xaxis: xyzStLabelData.map(x =>
                x !== '' ? {
                    x: x, // x 축의 값을 기준으로 세로선을 그립니다.
                    borderColor: '#cecece',
                    strokeDashArray: 0,
                    opacity: 0.5,
                } : '').filter(x => x !== ''),
        }
        
    };

    return (
        <div className='row'>
            <Card className={`custom-box-shadow rounded-2 w-100`} style={{ border: '1px solid #5d87ff', padding: 0 }}>
                <CardBody className={`rounded-2 align-items-center text-center`} style={{margin:0, padding: 0}} >
                    <ReactApexChart
                        options={options}
                        series={options.series}
                        width={'100%'}
                        type={'line'}
                        height={400}
                    />
                    <CardSubTitle className='text-center' style={{marginBottom: 27}}>
                        {type === 'day' ? '(단위: 시)' : type === 'week' ? '(단위: 일)' : '(단위: 주)'}
                    </CardSubTitle>
                </CardBody>
            </Card>
        </div>
    );
});

export default StData;
